import React from 'react';

const massContext = React.createContext(null);

export const MassProvider = massContext.Provider;
export const MassConsumer = massContext.Consumer;

export function massCntentHOC(Component) {
  class MassComponent extends React.Component {
    render() {
      const { forwardRef, ...props } = this.props;
      return <MassConsumer>{context => <Component {...props} {...context} ref={forwardRef} />}</MassConsumer>;
    }
  }
  return React.forwardRef((props, ref) => <MassComponent {...props} forwardRef={ref} />);
}
