import React from "react";
import { Checkbox } from "antd";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
function MassCheckButton({ total = 0, allTotal = 0, type = 0, indeterminate, checked, onClickAllCheck, exportMass, changType, handleDetle }) {
  return (
    <div className="mass-check-button">
      <div className={`mass-check-box ${type === 1 ? "mass-check-box-active" : ""}`}>
        <div className="mass-button">
          <AuthComponent actionName="masscontrolAdministration">
            <div className="mass-check" onClick={() => changType(1)}>
              <IconFont type={"icon-S_Bar_List"} theme="outlined" />
              批量操作
            </div>
          </AuthComponent>
          <div></div>
          <div className="mass-control-total">
            共<span className="total-span">{total}</span>/{allTotal}条线索
          </div>
        </div>
        <div className="mass-button">
          <Checkbox className="mass-button-ckeckbox" onChange={onClickAllCheck} indeterminate={indeterminate} checked={checked}>
            全选
          </Checkbox>
          <div className="mass-button-group">
            <div className="button-all cancel" onClick={() => changType(0)}>
              取消
            </div>
            <div className="button-all delete" onClick={() => handleDetle()}>
              删除
            </div>
            <div className="button-all export" onClick={exportMass}>
              导出
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MassCheckButton;
