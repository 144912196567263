import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MassProvider } from './components/massContext';
import MassView from './view';

@withRouter
@Decorator.withEntryLog()
class MassControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 线索列表
      loading: false,
      total: 0, // 当前列表总数
      allTotal: 0, // 全部线索总数
      hasLoadMore: false, // 是否有更多
      activeMass: undefined, // 选择的线索
      oldActiveMass: undefined, // 备用选择的
      searchData: {
        address: undefined,
        content: undefined,
        endTime: undefined,
        startTime: undefined,
        status: undefined,
        type: undefined,
        limit: 24,
        offset: 0
      } // 搜索条件
    };
  }

  componentDidMount() {
    this.queryClueList().then(res => {
      this.setState({
        activeMass: Array.isArray(res) && res.length ? res[0] : undefined
      });
      if(Array.isArray(res) && res.length && res[0]) {
        this.saveLog(res[0]);
      }
    });
    this.countClue();
  }

  countClue = async () => {
    const conutRes = await Service.massControl.countClue();
    this.setState({ allTotal: conutRes.data });
  };

  /**
   * @desc 获取线索列表
   * @param {object} parms 搜索条件
   * @param {boolean} loadMore 是否滚动加载
   */
  queryClueList = async (parms = {}, loadMore = false) => {
    await this.setState({ loading: true, hasLoadMore: false });
    try {
      let { searchData, list, hasLoadMore, activeMass } = this.state;
      const option = Object.assign({}, searchData, parms);
      const listRes = await Service.massControl.queryClueList(option);
      let resList = listRes.data.list || [];
      if (loadMore) {
        resList = list.concat(resList);
      }
      if (resList.length === searchData.limit) {
        hasLoadMore = true;
      }
      this.setState({
        searchData: option,
        total: listRes.data.total || 0,
        list: resList,
        loading: false,
        hasLoadMore
        // activeMass: resList.length ? resList[0] : undefined,
      });
      return resList;
    } catch (err) {
      this.setState({
        list: [],
        loading: false,
        hasLoadMore: false,
        activeMass: undefined
      });
    }
  };

  /**
   * @desc 修改查询参数
   * @param {Boolean} search 是否搜索
   * @param {Boolean} loadMore 是否为滚动加载
   */
  mergeSearchData = async (parms = {}, search = true, loadMore = false) => {
    if (search) {
      const list = await this.queryClueList(parms, loadMore);
      if (!loadMore) {
        await this.setState({
          activeMass: Array.isArray(list) && list.length ? list[0] : undefined
        });
      }
    } else {
      const { searchData } = this.state;
      const option = Object.assign({}, searchData, parms);
      await this.setState({
        searchData: option
      });
    }
  };

  /**
   * @desc 切换tabs
   * @parms {number} key 0 - 4
   */
  getActiveKey = key => {
    let option = { type: undefined };
    if (key === 0) {
      option.type = undefined;
    } else {
      option.type = key;
    }
    SocketEmitter.emit(SocketEmitter.eventName.massCheckAll)
    this.mergeSearchData(option);
  };

  clickCard = async parms => {
    await this.setState({
      activeMass: parms
    });
    this.saveLog(parms);
  };

  saveLog = (parms) => {
    let logInfo = {
      code: 108201,
      parent: 108200,
      text: '查看线索',
      description: `查看【${Dict.getLabel('massType', parms.type)}】的线索，线索ID：${parms.id}`,
    };
    Service.logger.save(logInfo);
  }

  /**
   * @desc 处理线索
   */
  handleMass = async value => {
    const { activeMass, searchData } = this.state;
    const id = activeMass.id;
    let parms = {
      id,
      comment: value
    };
    await Service.massControl.dealClue(parms, activeMass);
    await this.mergeSearchData();
    let chose;
    if (searchData.status === 0) {
      chose = this.state.list[0];
    } else {
      chose = this.state.list.find(v => v.id === id);
    }
    this.setState({
      activeMass: chose
    });
  };

  updateComment = async parms => {
    const { activeMass } = this.state;
    const id = activeMass.id;
    if (parms.id) {
      await Service.massControl.updateComment(parms, activeMass);
    } else {
      const option = {
        clueId: id,
        comment: parms.comment
      };
      await Service.massControl.saveComment(option, activeMass);
    }
    await this.mergeSearchData();
    const list = this.state.list;
    const chose = list.find(v => v.id === id);
    this.setState({
      activeMass: chose
    });
  };

  render() {
    return (
      <MassProvider
        value={{
          ...this.state,
          mergeSearchData: this.mergeSearchData,
          getActiveKey: this.getActiveKey,
          clickCard: this.clickCard,
          handleMass: this.handleMass,
          updateComment: this.updateComment
        }}>
        <MassView />
      </MassProvider>
    );
  }
}

export default MassControl;
