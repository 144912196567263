import React, { Component } from 'react';
import MassCheckButton from '../massCheckButton';
import { Checkbox, Spin, message } from 'antd';
import { massCntentHOC } from '../massContext';

import './index.less';

const CheckboxGroup = Checkbox.Group;
const NoData = Loader.loadBaseComponent('NoData');
const Loading = Loader.Loading;
const SearchInput = Loader.loadBaseComponent('SearchInput');
const MassTime = Loader.loadBusinessComponent('MassControl', 'MassTime');
const MassControlCard = Loader.loadBusinessComponent('Card', 'MassControlCard');
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');
const ConfirmComponent = Loader.loadBusinessComponent('ConfirmComponent');

const massButtonList = [{ value: undefined, label: '全部' }, { value: 1, label: '已处理' }, { value: 0, label: '未处理' }];

@massCntentHOC
class MassList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0, // 0 批量操作  1全选显示卡片单选按钮
      indeterminate: false, // 全选按钮是否半选
      checked: false, // 全选按钮是否选中
      chooseList: [],
      massVisible: false,
    };
    this.infinitRef = React.createRef();
    SocketEmitter.on(SocketEmitter.eventName.massCheckAll,this.handleMassCheckAll);
  }

  handleMassCheckAll = () => {
    this.changType(0)
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.massCheckAll, this.handleMassCheckAll);
  }

  componentWillReceiveProps(nextPorps) {
    if (nextPorps.activeMass !== this.props.activeMass) {
      this.updateList();
    }
  }
  // 关键字搜索
  inputHandleChange = value => {
    this.handleChange({ content: value, offset: 0 });
  };

  onGroupChange = async value => {
    const { list } = this.props;
    let indeterminate = false,
      checked = false,
      length = value.length;
    if (length < list.length && length > 0) {
      indeterminate = true;
      checked = false;
    }
    if (length === list.length) {
      checked = true;
      indeterminate = false;
    }
    await this.setState({
      indeterminate,
      checked,
      chooseList: value
    });
    this.updateList();
  };

  changType = async parms => {
    let { chooseList, checked, indeterminate } = this.state;
    if (parms === 0) {
      chooseList = [];
      checked = false;
      indeterminate = false;
    }
    await this.setState({
      type: parms,
      chooseList,
      checked,
      indeterminate
    });
    this.updateList();
  };

  onClickAllCheck = async event => {
    const { list } = this.props;
    let checked = event.target.checked,
      chooseList = [];
    if (checked) {
      chooseList = list;
    }
    await this.setState({
      checked: event.target.checked,
      indeterminate: false,
      chooseList
    });
    this.updateList();
  };

  /**
   * @desc 批量删除线索
   */
  handleDetle = async data => {
    const { chooseList } = this.state;
    let arr = [];
    if (data) {
      arr.push(data);
    } else {
      arr = chooseList;
    }
    if(arr.length === 0) {
      message.info('请选择需要删除的线索');
      return
    }
    this.setState({
      massVisible: true,
      chooseList: arr,
    })
  };

  deleteClub = async () => {
    const { mergeSearchData } = this.props;
    const { chooseList } = this.state;
    let arr = [];
    chooseList.forEach(v => arr.push(v.id));
    await Service.massControl.deleteClue({ ids: arr }, chooseList);
    mergeSearchData && mergeSearchData();
    this.setState({
      massVisible: false,
      chooseList: []
    })
  }

  handleChange = parms => {
    this.setState({checked:false})
    const { mergeSearchData } = this.props;
    mergeSearchData && mergeSearchData(parms);
  };

  loadMore = () => {
    const { mergeSearchData, searchData } = this.props;
    const parms = { offset: searchData.offset + searchData.limit };
    mergeSearchData && mergeSearchData(parms, true, true);
  };

  updateList = () => {
    this.infinitRef && this.infinitRef.current && this.infinitRef.current.forceUpdateGrid();
  };

  clickCard = parms => {
    const { clickCard } = this.props;
    clickCard &&
      clickCard(parms).then(() => {
        this.updateList();
      });
  };

  /**
   * @desc 导出线索
   * @param { Array } parms 线索id数组
   */
  exportMass = async () => {
    const { chooseList } = this.state;
    let arr = chooseList.map(v => v.id);
    await Service.massControl.exportClueByIds({ clueIds: arr });
    await this.setState({
      chooseList: [],
      checked: false,
      indeterminate: false
    });
    this.updateList();
  };

  closeModal = () => {
    this.setState({
      massVisible: false,
      chooseList: [],
    })
  }

  render() {
    const { type, indeterminate, checked, chooseList, massVisible } = this.state;
    const { list = [], infinitKey, hasLoadMore = false, loading, searchData = {}, activeMass = {}, total = 0, allTotal = 0 } = this.props;
    const { status, startTime, endTime, content } = searchData;
    return (
      <div className="mass-list">
        <div className="mass-list-header">
          <SearchInput value={content} placeholder="请输入线索关键字搜索" onChange={this.inputHandleChange} />
          <MassTime onChange={this.handleChange} startTime={ startTime } endTime={endTime } />
          <div className="mass-list-header-select">
            {massButtonList.map(v => (
              <div className={`select-button ${status === v.value ? 'active' : ''}`} onClick={() => this.handleChange({ status: v.value, offset: 0 })}>
                {v.label}
              </div>
            ))}
          </div>
          <MassCheckButton
            total={total}
            allTotal={allTotal}
            changType={this.changType}
            type={type}
            indeterminate={indeterminate}
            checked={checked}
            onClickAllCheck={this.onClickAllCheck}
            handleDetle={this.handleDetle}
            exportMass={this.exportMass}
          />
        </div>
        <div className="mass-list-content">
          <CheckboxGroup onChange={this.onGroupChange} value={chooseList} className="mass-list-content-checkgroup">
            <Spin className="mass-list-content-spin" spinning={loading}>
              {list.length ? (
                <InfiniteScrollLayout
                  itemWidth={300}
                  itemHeight={154}
                  key={infinitKey}
                  rowCount={1}
                  data={list}
                  hasLoadMore={hasLoadMore}
                  ref={this.infinitRef}
                  loadMore={this.loadMore}
                  renderItem={item => (
                    <MassControlCard data={item} type={type} handleDetle={this.handleDetle} activeId={activeMass.id} onClick={this.clickCard} />
                  )}
                />
              ) : loading ? (
                <Loading />
              ) : (
                <NoData />
              )}
            </Spin>
          </CheckboxGroup>
        </div>
        <ConfirmComponent
          visible={massVisible}
          title="删除线索"
          icon="icon-S_Edit_Delete"
          width={320}
          className="mass-modal"
          onCancel={this.closeModal}
          onOk={this.deleteClub}>
          <div className="text">你确定要删除该线索</div>
        </ConfirmComponent>
      </div>
    );
  }
}

export default MassList;
